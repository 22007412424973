import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    boxShadow:
      '0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%)',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    boxSizing: 'border-box',
    overflowY: 'auto',
    width: '560px',
    padding: '20px 24px',
    '& p': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: '.03125em',
      fontWeight: 400,
      margin: 0,
    },
  },
  // notBtn: {
  //   background: 'none',
  //   border: 'none',
  //   cursor: 'pointer',
  // },
  form: { display: 'flex', flexDirection: 'column' },
  orangeBtn: {
    color: 'white',
    marginBottom: '10px',
    // marginBottom: '2rem',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '.0892857143em',
    '&:hover': {
      background: '#fe8931',
    },
  },
  disclaimer: {
    '& .MuiFormControlLabel-label': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: '.03125em',
      fontWeight: 400,
    },
  },
  backBtn: {
    color: '#fe8931',
  },
  submittedText: {
    textAlign: 'center',
    fontFamily: 'Libre Franklin',
  },
}));

export default useStyles;
