import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  jumbotron: {
    backgroundSolor: '#fff',
    borderRadius: '.125rem',
    boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
    padding: '4rem 2rem',
    marginBottom: '2rem',
    margin: '3rem',
  },
  jumboH2: {
    fontWeight: 300,
    fontSize: '2rem',
    margin: '0 0 1.5rem',
    lineHeight: 1.2,
    fontFamily: 'Roboto',
    color: '#212529',
  },
  jumboP: {
    margin: '0 0 1rem',
    fontWeight: 300,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    lineHeight: 1.5,
    color: '#212529',
    '& a': {
      textDecoration: 'none',
      color: '#007bff',
    },
  },
  card: {
    flex: '0 0 33.333333%',
    maxWidth: '33.333333%',
    margin: '0 0.75rem',
    fontSize: '1rem',
    boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
  },
  cardHeader: {
    padding: '.75rem 1.25rem',
    marginBottom: 0,
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    '& h2': {
      margin: 0,
      fontSize: '1.25rem',
      fontWeight: 300,
      lineHeight: 1.2,
    },
    '& a': {
      textDecoration: 'none',
      color: '#007bff',
    },
    '& p': {
      margin: 0,
      fontWeight: 400,
    },
  },
  cardBody: {
    padding: '.75rem 1.25rem',
    marginBottom: 0,
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    '& p': {
      margin: 0,
      color: 'black',
    },
  },
  toggle: {
    padding: '.75rem 1.25rem',
  },
}));

export default useStyles;
