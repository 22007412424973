import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../state/hooks';

import { Button } from '@material-ui/core';

import Note from './components/Note';
import Response from './components/Response';
import Loader from './components/Loader';

import {
  fetchResponses,
  logout,
  favorite,
  unfavorite,
} from '../../state/actions';
import { authActions } from '../../state/auth';

import useStyles from './style';

function Dashboard(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [responses, setResponses] = useState([]);

  const auth = useAppSelector(state => state.auth);

  const classes = useStyles();

  const logoutHandler = async () => {
    await logout();
    dispatch(authActions.logout());
    history.push('/recruiter');
  };

  const fetch = useCallback(async () => {
    if (!auth.isLoggedIn) {
      history.push('/recruiter/login');
    }
    const res = await fetchResponses(auth.user.campaigns[0], auth.user.token);
    setResponses(res);
  }, [auth, history]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const toggleHandler = async (response: string, fav: boolean) => {
    if (fav) {
      await favorite(auth.user.token, auth.user.campaigns[0], response);
    } else {
      await unfavorite(auth.user.token, auth.user.campaigns[0], response);
    }
  };

  const renderResponses = () => {
    return responses.map((i: any) => {
      return <Response key={i._id} data={i} onToggle={toggleHandler} />;
    });
  };

  return (
    <div className={classes.root}>
      <Note />
      <section className={classes.btnList}>
        <Button className={classes.btn} onClick={logoutHandler}>
          Log Out
        </Button>
      </section>
      <h2 className={classes.campaignLabel}>
        Responses
        {/* {auth.user.campaigns[0]} */}
      </h2>
      {responses.length === 0 && <Loader />}
      {responses.length > 0 && (
        <div className={classes.cardContainer}>{renderResponses()}</div>
      )}
      <section style={{ height: '30px' }}></section>
    </div>
  );
}

export default Dashboard;
