import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import Navbar from '../../components/UI/Navbar';
import Footer from '../../components/UI/Footer';
import ModalTemplate from './components/ModalTemplate';

import {
  fetchArticles,
  fetchArticle,
  fetchCampaignQuestions,
} from '../../state/actions';

import useStyles from './style';

function ArticleView(): JSX.Element {
  const classes = useStyles();
  const { id } = useParams() as any;
  const [articles, setArticles] = useState<any>([]);
  const [article, setArticle] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const [modal, showModal] = useState(true);

  const fetch = useCallback(async () => {
    const data = await fetchArticle(id);
    const q = await fetchCampaignQuestions(data.campaign);
    const a = await fetchArticles();
    setArticles(a);
    setArticle(data);
    setQuestions(q);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const closeModal = () => {
    showModal(false);
  };

  const renderLinks = () => {
    return articles.map((i: any) => {
      return (
        <a key={i._id} href={`/article/${i._id}`}>
          {i.company_name}
        </a>
      );
    });
  };

  return (
    <div className={classes.root}>
      {modal && (
        <ModalTemplate
          // onSubmit={submitHandler}
          onClose={closeModal}
          questions={questions}
          campaign={article.campaign}
        />
      )}
      <Navbar />
      <section className={classes.banner}>
        <h1 className={classes.sponsored}>
          SPONSORED CONTENT{' '}
          <span className={classes.sponsorName}>
            {article.company_name ? article.company_name.toUpperCase() : ''}
          </span>
        </h1>
      </section>
      <Grid container spacing={0} className={classes.grid}>
        <Grid
          item
          xs={2}
          className={`${classes.gridItem} ${classes.left}`}
          style={{ paddingRight: '0px' }}
        >
          <div className={classes.nav}>
            <Link to='/'>Home</Link>
            {renderLinks()}
          </div>
        </Grid>
        <Grid item xs={8} className={`${classes.gridItem} ${classes.right}`}>
          <h1 className={classes.title}>
            {article.title ? article.title.toUpperCase() : ''}
          </h1>
          <img
            className={classes.image}
            src={article.img_path}
            alt={article.title}
          ></img>
          <div
            dangerouslySetInnerHTML={{ __html: article.raw_html }}
            className={classes.articleBody}
          ></div>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.gridItem}
          style={{ paddingLeft: '0px' }}
        ></Grid>
      </Grid>
      <section className={classes.bottomDiv}>
        <h2 className={classes.sponsor}>
          {article.company_name ? article.company_name.toUpperCase() : ''}
        </h2>
        <button
          type='button'
          className={`${classes.notBtn} ${classes.applyBtn}`}
          onClick={() => showModal(true)}
        >
          APPLY
        </button>
      </section>
      <section className={classes.disclaimer}>
        This content was created by 48U Studios, the Daily Princetonian’s brand
        marketing studio. The Daily Princetonian’s editorial staff was not
        involved in the production of the content on this page.
      </section>
      <Footer />
    </div>
  );
}

export default ArticleView;
