import { authActions } from './auth';

export const fetchArticles = async (): Promise<any> => {
  const fetchArticles = async () => {
    const response = await fetch('/api/articles', {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('fetching articles failed.');
    }

    const data = response.json();

    return data;
  };

  try {
    const articles = await fetchArticles();
    return articles;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const fetchArticle = async (id: any): Promise<any> => {
  const fetchArticle = async () => {
    const response = await fetch(`/api/articles/${id}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('fetching articles failed.');
    }

    const data = response.json();

    return data;
  };

  try {
    const articles = await fetchArticle();
    return articles[0];
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const fetchCampaignQuestions = async (id: any): Promise<any> => {
  const fetchQuestions = async () => {
    const response = await fetch(`/api/campaign/questions/${id}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('fetching questions failed.');
    }

    const data = response.json();

    return data;
  };

  try {
    const articles = await fetchQuestions();
    return articles;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const sendResponse = async (
  campaign: string,
  answers: Array<any>,
  name: string,
  email: string
): Promise<any> => {
  const send = async () => {
    const response = await fetch(`/api/submit`, {
      method: 'POST',
      body: JSON.stringify({
        campaign,
        answers,
        student_name: name,
        student_email: email,
      }),
    });

    if (!response.ok) {
      throw new Error('fetching questions failed.');
    }

    return;
  };

  try {
    await send();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const signup = async (email: string, password: string): Promise<any> => {
  const send = async () => {
    const response = await fetch(`/api/signup`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error('signing up failed.');
    }

    return;
  };

  try {
    await send();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const login = async (email: string, password: string): Promise<any> => {
  const token = async () => {
    const response = await fetch(`/api/token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error('signing up failed.');
    }

    const data = await response.json();

    return data;
  };

  const profile = async (token: string) => {
    const response = await fetch(`/api/profile`, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: JSON.stringify({
        userToken: token,
      }),
    });

    if (!response.ok) {
      console.log(response);
      throw new Error('signing up failed.');
    }

    const data = await response.json();

    return data;
  };

  try {
    const tokenResponse = await token();
    const loginResponse = await profile(tokenResponse.token);
    return { ...loginResponse, userToken: tokenResponse.token };
  } catch (err) {
    return null;
  }
};

export const logout = async (): Promise<any> => {
  const send = async () => {
    const response = await fetch(`/api/profile`, {
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    if (!response.ok) {
      throw new Error('logging out failed.');
    }

    return;
  };

  try {
    await send();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const fetchResponses = async (id: any, token: any): Promise<any> => {
  const send = async () => {
    const response = await fetch(`/api/responses/${id}`, {
      method: 'POST',
      body: JSON.stringify({
        userToken: token,
      }),
    });

    if (!response.ok) {
      throw new Error('fetching articles failed.');
    }

    const data = response.json();

    return data;
  };

  try {
    const data = await send();
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const favorite = async (
  userToken: string,
  campaign: string,
  response: string
): Promise<any> => {
  const send = async () => {
    const res = await fetch(`/api/favorite`, {
      method: 'PUT',
      body: JSON.stringify({
        userToken,
        campaign,
        response,
      }),
    });

    if (!res.ok) {
      throw new Error('favoriting failed.');
    }

    return;
  };

  try {
    return await send();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const unfavorite = async (
  userToken: string,
  campaign: string,
  response: string
): Promise<any> => {
  const send = async () => {
    const res = await fetch(`/api/favorite`, {
      method: 'DELETE',
      body: JSON.stringify({
        userToken,
        campaign,
        response,
      }),
    });

    if (!res.ok) {
      throw new Error('unfavoriting failed.');
    }

    return;
  };

  try {
    return await send();
  } catch (err) {
    console.error(err);
    return null;
  }
};
