import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  navbar: {
    backgroundColor: theme.customPalette.beige,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    borderBottom: '2px solid black',
    fontFamily: 'Inconsolata',
    fontWeight: 700,
    '& a': {
      textDecoration: 'none',
      color: theme.customPalette.black,
      cursor: 'pointer',
      '&:hover': {
        color: theme.customPalette.orange,
      },
    },
    '& h3': {
      margin: '0px',
      fontSize: '1.5rem',
    },
    '& h4': {
      margin: '0px',
      fontSize: '1.25rem',
    },
  },
  footer: {
    backgroundColor: theme.customPalette.black,
    position: 'sticky',
    // bottom: '0px',
    width: '100%',
    boxShadow: '0px -5px 20px grey',
    marginTop: '5rem',
    // height: '200px',
  },
  footerLinks: {
    flexFlow: 'row wrap',
    padding: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
