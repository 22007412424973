import { Link } from 'react-router-dom';
import useStyles from './style';

function Navbar() {
  const classes = useStyles();
  return (
    <nav className={classes.navbar}>
      <Link to='/'>
        <h3>48U Studios</h3>
      </Link>
      <a href='http://48u.dailyprincetonian.com/'>
        <h4>About</h4>
      </a>
    </nav>
  );
}

export default Navbar;
