import { useState } from 'react';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import useStyles from './style';
import './color.css';

interface SelectInfo {
  id: string;
  index: number;
  field: string;
  options: Array<string>;
  required: boolean;
  placeholder: string;
  changeHandler: (
    id: string,
    input: string,
    index: number,
    field: string,
    required: boolean
  ) => void;
}

function SelectInput(props: SelectInfo): JSX.Element {
  const classes = useStyles();

  const [value, setValue] = useState('');
  const { id, changeHandler, options, field, index, required, placeholder } =
    props;

  const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onChangeHandler = (e: any) => {
    setValue(e.target.value);
    changeHandler(id, e.target.value, index, placeholder, required);
  };

  const renderOptions = () => {
    return options.map(element => {
      return (
        <MenuItem key={element} value={element}>
          {element}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <p style={{ marginBottom: '10px' }}>{field}</p>
      <FormControl variant='outlined' className={`${classes.textField}`}>
        <InputLabel id='demo-simple-select-outlined-label'>
          {placeholder ? capitalize(placeholder) : field} {required ? '*' : ''}
        </InputLabel>
        <Select
          color='secondary'
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          value={value}
          onChange={onChangeHandler}
          label={placeholder}
          // required={required}
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </>
  );
}

SelectInput.defaultProps = {
  // required: false,
  // placeholder: '',
};

export default SelectInput;
