import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1111,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    backdropFilter: 'blur(6px)',
  },
  modal: {
    maxWidth: '90vw',
    backgroundColor: 'white',
    margin: '50px',
    padding: '15px 24px',
    boxShadow: '0 11px 15px -7px rgb(0 0 0 / 20%),',
    zIndex: 1111,
    maxHeight: '96vh',
    overflowX: 'auto',
  },
  textField: {
    marginBottom: '10px',
  },
  textInput: {
    width: '100%',
    marginTop: '10px',
  },
}));

export default useStyles;
