import { Fragment, useEffect, useState } from 'react';

import { Typography, Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import CloseIcon from '@material-ui/icons/Close';

import Modal from '../../../components/Modal/Modal';
import TextInput from '../../../components/Modal/TextInput';
import SelectInput from '../../../components/Modal/SelectInput';

import { sendResponse } from '../../../state/actions';

import useStyles from './style';

interface ModalProps {
  onClose: () => void;
  campaign: string;
  questions: Array<any>;
}

let init = false;

function ModalTemplate(props: ModalProps): JSX.Element {
  const [submitted, setSubmitted] = useState(false);
  const [value, setValue] = useState<any>([]);
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { onClose, questions, campaign } = props;
  const [error, setError] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setValue(
      new Array(questions.length).fill({ _id: '', body: '', required: true })
    );
  }, [questions]);

  const checkHandler = () => {
    setChecked(prevState => !prevState);
  };

  const renderInputs = () => {
    return questions.map((i, index) => {
      if (i.type === 'select') {
        return (
          <SelectInput
            key={i._id}
            index={index}
            id={i._id}
            field={i.body}
            options={i.options}
            placeholder={i.contains}
            changeHandler={inputChangeHandler}
            required={i.required}
          />
        );
      } else {
        return (
          <TextInput
            key={i._id}
            index={index}
            id={i._id}
            field={i.body}
            placeholder={i.contains}
            changeHandler={inputChangeHandler}
            required={i.required}
          />
        );
      }
    });
  };

  const inputChangeHandler = (
    id: string,
    input: string,
    index: number,
    field: string,
    required: boolean
  ) => {
    // console.log(field);
    if (field === 'name') {
      setName(input);
    } else if (field === 'email') {
      setEmail(input);
    }
    let temp = value;
    temp[index] = { _id: id, body: input, required };
    setValue(temp);
  };

  const validate = () => {
    let valid = true;

    value.map((i: any) => {
      if (i.required && i.body === '') {
        valid = false;
      }
    });

    return valid;
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();

    const valid = validate();

    if (valid) {
      await sendResponse(campaign, value, name, email);
      setSubmitted(true);
      setError('');
    } else {
      setError('Please fill out all required fields.');
    }
  };

  const renderForm = () => {
    return (
      <>
        <p style={{ marginBottom: '.5rem' }}>
          Want to read first and apply later?
        </p>
        <Button
          className={classes.orangeBtn}
          variant='contained'
          color='secondary'
          onClick={onClose}
          style={{ marginBottom: '2rem' }}
        >
          SKIP
        </Button>
        <form className={classes.form}>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {renderInputs()}
          <p>
            By clicking Submit, you agree that you are over the age of 16, that
            the information you provide may be used subject to the Daily
            Princetonian’s Privacy Policy, and that the Daily Princetonian may
            share the information you provide with BGB Group, which may contact
            you using the information you provide.
          </p>
          <FormControlLabel
            className={classes.disclaimer}
            control={
              <Checkbox
                checked={checked}
                onChange={checkHandler}
                name='checked'
                color='secondary'
              />
            }
            label='I have read and understood the above.'
          />
          <Button
            className={classes.orangeBtn}
            variant='contained'
            color='secondary'
            disabled={!checked}
            type='submit'
            onClick={submitHandler}
          >
            Submit
          </Button>
          <Button
            className={classes.backBtn}
            variant='outlined'
            onClick={onClose}
          >
            Back
          </Button>
        </form>
      </>
    );
  };

  const renderSubmitted = () => {
    return (
      <>
        <h2 className={classes.submittedText}>
          Your response has been submitted!
        </h2>
        <Button
          className={classes.orangeBtn}
          variant='contained'
          color='secondary'
          type='submit'
          onClick={onClose}
        >
          RETURN
        </Button>
      </>
    );
  };

  return (
    <Modal className={classes.modal} onClose={onClose}>
      {!submitted && renderForm()}
      {submitted && renderSubmitted()}
    </Modal>
  );
}

export default ModalTemplate;
