import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  nav: {
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
    padding: '10%',
    border: '2px solid #000',
    backgroundColor: '#ffedcd',
    '& a': {
      marginBottom: '10%',
      textDecoration: 'none',
      color: '#000',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 1.5,
    },
  },
  banner: {
    backgroundColor: '#212529',
    padding: '12px 0',
  },
  sponsored: {
    textAlign: 'center',
    color: theme.customPalette.beige,
    margin: 0,
    fontSize: '16px',
    fontFamily: 'Libre Franklin',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  sponsorName: {
    fontSize: '24px',
    marginLeft: '10px',
  },
  grid: {
    paddingTop: '30px',
  },
  gridItem: {
    padding: '0 40px',
  },
  left: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  right: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  image: {
    width: '100%',
    marginBottom: '2.5rem',
  },
  title: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
    fontFamily: 'Libre Franklin',
    fontSize: '2rem',
  },
  articleBody: {
    fontSize: '1.15rem',
    marginTop: 0,
    marginBottom: '1rem',
    fontFamily: 'Minion Pro',
  },
  bottomDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sponsor: {
    marginBottom: '1rem',
    marginTop: '2rem',
    fontSize: '2.5rem',
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: 'Libre Franklin',
  },
  notBtn: {
    border: 'none',
    backgroundColor: 'none',
    cursor: 'pointer',
  },
  applyBtn: {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '.0892857143em',
    borderRadius: 50,
    padding: '0 20px',
    height: '48px',
    color: 'white',
    background: '#028786',
    marginTop: '5px',
    // margin: '5px 0 30px',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.4)',
    '&:hover': {
      background: '#289190',
    },
  },
  disclaimer: {
    fontSize: '1.75rem',
    textAlign: 'center',
    padding: '0 15vw',
    fontWeight: 700,
    marginTop: '25vh',
    fontFamily: 'Minion Pro',
    color: theme.customPalette.black,
  },
}));

export default useStyles;
