import React, { useEffect, useRef, useCallback } from 'react';

import useStyles from './style';

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
  className: any;
  style?: any;
}

function Modal(props: ModalProps): JSX.Element {
  const node = useRef<HTMLDivElement>(null);
  const { onClose, children, className, style } = props;

  const classes = useStyles();

  // closing the modal
  // const handleClickOutside = useCallback(
  //   event => {
  //     if (node.current && node.current.contains(event.target)) {
  //       return;
  //     }
  //     onClose();
  //   },
  //   [onClose]
  // );

  // set up event listeners upon modal opening
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [handleClickOutside]);

  return (
    <div className={classes.modalContainer}>
      <div className={`${classes.modal} ${className}`} ref={node} style={style}>
        {children}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  style: null,
};

export default Modal;
