import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { FormControl, InputLabel } from '@material-ui/core';

import useStyles from './style';

interface InputProps {
  id: string;
  index: number;
  field: string;
  placeholder: string;
  required: boolean;
  changeHandler: (
    id: string,
    input: string,
    index: number,
    field: string,
    required: boolean
  ) => void;
}

function TextInput(props: InputProps): JSX.Element {
  const { id, index, field, placeholder, changeHandler, required } = props;
  const classes = useStyles();
  const [input, setInput] = useState('');

  const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onChangeHandler = (e: any) => {
    changeHandler(id, e.target.value, index, placeholder, required);
    setInput(e.target.value);
  };

  return (
    <FormControl className={`${classes.textField}`}>
      <p>{field}</p>
      <TextField
        id='outlined-basic'
        label={`${placeholder ? capitalize(placeholder) : field} ${
          required ? '*' : ''
        }`}
        variant='outlined'
        color='secondary'
        className={classes.textInput}
        value={input}
        onChange={onChangeHandler}
      />
    </FormControl>
  );
}

TextInput.defaultProps = {
  // placeholder: '',
};

export default TextInput;
