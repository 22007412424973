import Homepage from '../pages/home/Homepage';
import ArticleView from '../pages/article/ArticleView';
import About from '../pages/about/About';
import SignUp from '../pages/recruiter/SignUp';
import LogIn from '../pages/recruiter/LogIn';
import Dashboard from '../pages/recruiter/Dashboard';

const routes: Array<{
  path: string;
  exact: boolean;
  component: () => JSX.Element;
}> = [
  { path: '/', exact: true, component: Homepage },
  // { path: '/about', exact: true, component: About },
  { path: '/article/:id', exact: false, component: ArticleView },
  { path: '/recruiter', exact: true, component: SignUp },
  { path: '/recruiter/login', exact: true, component: LogIn },
  { path: '/recruiter/dashboard', exact: true, component: Dashboard },
];

export default routes;
