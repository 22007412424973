import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    customPalette: {
      beige: string;
      black: string;
      charcoal: string;
      orange: string;
      lightOrange: string;
      button: string;
    };
  }
  interface ThemeOptions {
    customPalette?: {
      beige: string;
      black: string;
      charcoal: string;
      orange: string;
      lightOrange: string;
      button: string;
    };
  }
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#007bff',
      // main: '#FFFFFF',
      // dark: '#EDEDED',
    },
    secondary: {
      main: '#fe7e14',
    },
  },
  customPalette: {
    beige: '#ffedcd',
    black: '#1A1A1A',
    charcoal: '#212529',
    orange: '#e2712f',
    lightOrange: '#ff8b5c',
    button: '#fd7e14',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
