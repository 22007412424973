import { useState } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';

import useStyles from './style';

interface CardProps {
  data: any;
  onToggle: (response: string, value: boolean) => Promise<any>;
}

function Response(props: CardProps): JSX.Element {
  const { data, onToggle } = props;
  const [favorite, setFavorite] = useState<boolean>(data.favorited);
  const classes = useStyles();

  const renderQuestions = () => {
    return data.answers.map((i: any) => {
      return (
        <>
          <div className={classes.cardHeader}>
            <p>{i.question}</p>
          </div>
          <div className={classes.cardBody}>
            <p>{i.body}</p>
          </div>
        </>
      );
    });
  };

  const toggleHandler = (e: any) => {
    onToggle(data._id, !favorite);
    setFavorite(prevState => !prevState);
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardHeader}>
        <h2>{data.student_name}</h2>
      </div>
      <div className={classes.cardHeader}>
        <a href={`mailto:${data.student_email}`}>{data.student_email}</a>
      </div>
      {renderQuestions()}
      <FormControlLabel
        className={classes.toggle}
        control={
          <Switch
            checked={favorite}
            onChange={toggleHandler}
            name='favorited'
          />
        }
        label='Favorite'
      />
    </div>
  );
}

export default Response;
