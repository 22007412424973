import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    margin: '3rem 0px',
  },
  headingText: {
    color: 'black',
    fontWeight: 500,
    fontSize: '4rem',
    margin: '0px',
    paddingTop: '2rem',
    textAlign: 'center',
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
      fontSize: '2.5rem',
    },
  },
  outlined: {
    color: theme.customPalette.beige,
    textStroke: '1.5px black',
  },
  tagline: {
    maxWidth: '960px',
    margin: 'auto',
    fontFamily: 'Inconsolata, sans-serif',
    fontSize: '2rem',
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.3,
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
      fontSize: '1.5rem',
    },
  },
  cardContainer: {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '90vw',
    maxWidth: '900px',
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
    },
  },
  disclaimer: {
    fontSize: '1.75rem',
    textAlign: 'center',
    paddingLeft: '15vw',
    paddingRight: '15vw',
    fontWeight: 700,
    marginTop: '25vh',
    fontFamily: 'Minion Pro',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
}));

export default useStyles;
