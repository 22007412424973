import { createSlice } from '@reduxjs/toolkit';

const initState = {
  isLoggedIn: false,
  user: {
    _id: '',
    campaigns: [],
    email: '',
    name: '',
    last_active: '',
    token: '',
  },
  error: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    login(state, action) {
      state.error = '';
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    error(state) {
      state.error = 'Your username or password is incorrect.';
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = {
        _id: '',
        campaigns: [],
        email: '',
        name: '',
        last_active: '',
        token: '',
      };
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
