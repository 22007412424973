import './loader.css';

function Loader(): JSX.Element {
  return (
    <div className='loader-container'>
      <div className='loader'>Loading...</div>
    </div>
  );
}

export default Loader;
