import { useRef } from 'react';
import { useHistory } from 'react-router';

import { authActions } from '../../state/auth';
import { login } from '../../state/actions';

import { useAppDispatch, useAppSelector } from '../../state/hooks';

import useStyles from './style';

function LogIn(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const classes = useStyles();

  const { error } = useAppSelector(state => state.auth);

  const submitHandler = async (e: any) => {
    e.preventDefault();
    const data = await login(
      email.current ? email.current.value : '',
      password.current ? password.current.value : ''
    );

    if (data === null) {
      console.log('failed');
      dispatch(authActions.error());
    } else {
      dispatch(authActions.login(data));
      history.push('/recruiter/dashboard');
    }
  };

  return (
    <div className={classes.loginRoot}>
      <form className={classes.form} onSubmit={submitHandler}>
        <h2>Recruiter Log In</h2>
        <input
          className={classes.input}
          type='text'
          placeholder='Recruiter Email'
          ref={email}
          required
        />
        <input
          className={classes.input}
          type='password'
          placeholder='Recruiter Password'
          ref={password}
          required
        />
        <button type='submit' className={classes.submit}>
          LOG IN
        </button>
        <p className={classes.clearfix}>
          Contact{' '}
          <a href='mailto:tech@dailyprincetonian.com'>
            tech@dailyprincetonian.com
          </a>{' '}
          for assistance.
        </p>
        {error && (
          <p className={classes.clearfix} style={{ color: 'red' }}>
            {error}
          </p>
        )}
      </form>
    </div>
  );
}

export default LogIn;
