import { useState, useCallback, useEffect } from 'react';

import { fetchArticles } from '../../state/actions';

import Navbar from '../../components/UI/Navbar';
import Footer from '../../components/UI/Footer';

import Card from './components/Card';

import useStyles from './style';

export interface HomepageProps {}

function Homepage(): JSX.Element {
  const [articles, setArticles] = useState<any>([]);
  const classes = useStyles();

  const fetch = useCallback(async () => {
    const data = await fetchArticles();
    setArticles(data);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const renderArticles = () => {
    return articles.map((i: any) => {
      return <Card key={i._id} data={i} />;
    });
  };

  return (
    <>
      <Navbar />
      <div className={classes.root}>
        <header className={classes.heading}>
          <h1 className={classes.headingText}>
            <span className={classes.outlined}>TALENTED </span> STUDENTS.
          </h1>
          <h1 className={classes.headingText}>
            <span className={classes.outlined}>AWESOME </span>
            COMPANIES.
          </h1>
        </header>
        <h2 className={classes.tagline}>
          48U Studios provides a platform where students can learn about new
          opportunities and create meaningful connections with recruiters.
        </h2>
        <div className={classes.cardContainer}>{renderArticles()}</div>
        <div className={classes.disclaimer}>
          This content was created by 48U Studios, the Daily Princetonian’s
          brand marketing studio. The Daily Princetonian’s editorial staff was
          not involved in the production of the content on this page.
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
