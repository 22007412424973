import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '0 0 31%',
    maxWidth: '31%',
    border: '1px solid black',
    backgroundColor: theme.customPalette.beige,
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 10px',
    padding: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 46%',
      maxWidth: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '0 0 98%',
      maxWidth: '540px',
    },
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'Libre Franklin',
    cursor: 'pointer',
  },

  title: {
    color: 'black',
    fontSize: '1.75rem',
    marginTop: 0,
    marginBottom: '.5rem',
    '&:hover': {
      color: theme.customPalette.orange,
      textDecoration: 'underline',
    },
  },
  image: {
    width: '100%',
  },
  blurb: {
    marginTop: '.75rem',
    fontFamily: 'Inconsolata',
    fontWeight: 400,
    fontSize: '1.1rem',
  },
}));

export default useStyles;
