import useStyles from './style';

function Note(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.jumbotron}>
      <h2 className={classes.jumboH2}>High Quality Leads for your Business</h2>
      <p className={classes.jumboP}>
        On behalf of all the staff members of the Daily Princetonian, thank you
        again for choosing our lead acquisition service. As students fill out
        the information you have requested to access your sponsored content,
        their responses will appear on this dashboard. Below, you will find
        convenient shortcuts to export this information to a CSV, but we have
        also provided an interface to peruse the data in the browser. Please let
        us know if there is anything we can assist you with by contacting our
        tech team at&nbsp;
        <a
          href='mailto:tech@dailyprincetonian.com'
          target='_blank'
          rel='noreferrer'
        >
          <code>tech@dailyprincetonian.com</code>
        </a>
        &nbsp;or our business team at&nbsp;
        <a
          href='mailto:business@dailyprincetonian.com'
          target='_blank'
          rel='noreferrer'
        >
          <code>business@dailyprincetonian.com</code>
        </a>
        .
      </p>
    </div>
  );
}

export default Note;
