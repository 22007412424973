// import { Link } from 'react-router-dom';
import useStyles from './style';
import './footer.css';

function Footer() {
  const classes = useStyles();
  return (
    <div className={`${classes.footer} footer`}>
      <div className={classes.footerLinks}>
        <div className='footer-logo'>
          <h1
            className='white fortyeightu'
            style={{ paddingBottom: '0', marginBottom: 0 }}
            // style='padding-bottom:0;margin-bottom:0'
          >
            48U
          </h1>
          <h3 className='studios'>STUDIOS</h3>
          <a
            href='mailto:business@dailyprincetonian.com'
            className='button-white'
          >
            CONTACT US
          </a>
        </div>
        <div className='footer-links-section first'>
          <h6 className='white'>
            <a href='https://www.dailyprincetonian.com/section/news'>NEWS</a>
          </h6>
          <ul>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/student-life'
                className='offwhite'
              >
                Student Life
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/graduate-school'
                className='offwhite'
              >
                Grad School
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/u-affairs'
                className='offwhite'
              >
                U. Affairs
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/obituaries'
                className='offwhite'
              >
                Obituaries
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/academics'
                className='offwhite'
              >
                Academics
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/activism'
                className='offwhite'
              >
                Activism
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/research'
                className='offwhite'
              >
                Research
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/alumni'
                className='offwhite'
              >
                Alumni
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/local'
                className='offwhite'
              >
                Town
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/usg'
                className='offwhite'
              >
                USG
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/qa'
                className='offwhite'
              >
                Q&amp;A
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-links-section'>
          <h6 className='white'>
            <a href='https://www.dailyprincetonian.com/section/sports'>
              SPORTS
            </a>
          </h6>
          <ul>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/sports-insidelook'
                className='offwhite'
              >
                Inside Looks
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/sports-previews'
                className='offwhite'
              >
                Previews
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/sports-profiles'
                className='offwhite'
              >
                Profiles
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/sports-columns'
                className='offwhite'
              >
                Columns
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/on-tap'
                className='offwhite'
              >
                On Tap
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/sports-recaps'
                className='offwhite'
              >
                Recaps
              </a>
            </li>
            <li className='white'>&nbsp;</li>
          </ul>
          <h6 className='white'>
            <a href='https://www.dailyprincetonian.com/section/prospect'>
              THE PROSPECT
            </a>
          </h6>
          <ul>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/dispatches'
                className='offwhite'
              >
                Dispatches
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/culture'
                className='offwhite'
              >
                Culture
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/self'
                className='offwhite'
              >
                Self
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/arts'
                className='offwhite'
              >
                Arts
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-links-section'>
          <h6 className='white'>
            <a href='https://www.dailyprincetonian.com/section/opinion'>
              OPINION
            </a>
          </h6>
          <ul>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/letter-to-the-editor'
                className='offwhite'
              >
                Letter to the Editor
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/opinion-guest'
                className='offwhite'
              >
                Guest Submission
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/opinion-open'
                className='offwhite'
              >
                Open Letter
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/editorial'
                className='offwhite'
              >
                Editorial
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/columns'
                className='offwhite'
              >
                Column
              </a>
            </li>
            <li className='white'>&nbsp;</li>
          </ul>
          <h6 className='white'>
            <a href='https://www.dailyprincetonian.com/section/special'>
              PROJECTS
            </a>
          </h6>
          <ul>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/how-women-became-tigers'
                className='offwhite'
              >
                #HowWomenBecameTigers
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/power-of-a-united-people'
                className='offwhite'
              >
                Power of a United People
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/section/in-the-nations-service'
                className='offwhite'
              >
                In the Nation's Service
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-links-section last'>
          <h6 className='white'>
            <a href='https://www.dailyprincetonian.com/multimedia'>
              MULTIMEDIA
            </a>
          </h6>
          <ul>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/page/submit'
                className='offwhite'
              >
                Submit a Tip
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/page/contact'
                className='offwhite'
              >
                Contact Us
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/page/advertise'
                className='offwhite'
              >
                Advertise
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/page/masthead'
                className='offwhite'
              >
                Masthead
              </a>
            </li>
            <li className='white'>
              <a href='https://theprince.princeton.edu/' className='offwhite'>
                Archives
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/page/privacy'
                className='offwhite'
              >
                Privacy
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/page/donate-to-the-daily-princetonian'
                className='offwhite'
              >
                Donate
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.dailyprincetonian.com/page/terms-and-conditions'
                className='offwhite'
              >
                Terms
              </a>
            </li>
            <li className='white'>&nbsp;</li>
          </ul>
          <h6 className='white'>FOLLOW</h6>
          <ul>
            <li className='white'>
              <a
                href='https://www.instagram.com/dailyprincetonian/'
                className='offwhite'
              >
                Instagram
              </a>
            </li>
            <li className='white'>
              <a
                href='https://www.facebook.com/DailyPrincetonian'
                className='offwhite'
              >
                Facebook
              </a>
            </li>
            <li className='white'>
              <a
                href='https://open.spotify.com/show/5xeaopGvoY0teJn0atpNPM'
                className='offwhite'
              >
                Spotify
              </a>
            </li>
            <li className='white'>
              <a href='https://twitter.com/princetonian' className='offwhite'>
                Twitter
              </a>
            </li>
            <li className='white'>
              <a
                href='https://youtube.com/channel/UC6DZuLRHcb2Nf6bP3nna9VQ'
                className='offwhite'
              >
                YouTube
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
