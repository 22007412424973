import { Link } from 'react-router-dom';

import useStyles from './style';

interface CardProps {
  data: any;
}

function Card(props: CardProps): JSX.Element {
  const { data } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link to={`/article/${data._id}`} className={classes.link}>
        <h2 className={classes.title}>{data.title.toUpperCase()}</h2>
        <img
          className={classes.image}
          src={data.img_path}
          alt={data.title}
        ></img>
      </Link>
      <p className={classes.blurb}>{data.blurb}</p>
    </div>
  );
}

export default Card;
