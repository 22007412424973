import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
  },
  tagline: {
    margin: 0,
    marginTop: '70px',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  form: {
    margin: '10px auto 50px',
    width: '500px',
    background: '#f7f7f7',
    boxShadow: '0px 1px 1px rgb(0 0 0 / 30%)',
    padding: '30px',
    marginBottom: '2rem',
    '& h2': {
      margin: '0 0 15px',
      textAlign: 'center',
      fontWeight: 300,
      fontSize: '2rem',
      lineHeight: 1.2,
    },
  },
  input: {
    marginBottom: '1rem',
    minHeight: '38px',
    borderRadius: '2px',
    width: '100%',
    height: 'calc(1.5em + .75rem + 2px)',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#495057',
    backgroundSolor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  submit: {
    marginBottom: '1rem',
    color: '#4285f4',
    backgroundColor: '#dae5f7',
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 'bold',
    borderRadius: '2px',
    border: 'none',
    height: '50px',
    width: '100%',
  },
  clearfix: {
    margin: 0,
    marginBottom: '1rem',
    fontSize: '15px',
    fontWeight: 300,
    fontFamily: 'Roboto',
    '& a': {
      textDecoration: 'none',
      color: '#4285f4',
    },
  },
  loginRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  btnList: {
    margin: '20px 3rem',
    display: 'flex',
  },
  btn: {
    color: '#4285f4',
  },
  campaignLabel: {
    fontWeight: 300,
    fontSize: '2rem',
    marginBottom: '.5rem',
    lineHeight: 1.2,
    marginTop: 0,
    fontFamily: 'Roboto',
    margin: '0 3rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    paddingTop: '20px',
  },
  cardContainer: {
    margin: '30px 2.25rem 2.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Roboto',
    fontWeight: 300,
    color: '#212529',
    lineHeight: 1.5,
  },
}));

export default useStyles;
