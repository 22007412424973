import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Note from './components/Note';

import { signup } from '../../state/actions';

import useStyles from './style';

function SignUp(): JSX.Element {
  const history = useHistory();
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const classes = useStyles();

  const redirectHandler = () => {
    history.push('/recruiter/login');
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    await signup(
      email.current ? email.current.value : '',
      password.current ? password.current.value : ''
    );
    history.push('/recruiter/login');
  };

  return (
    <div className={classes.root}>
      <Note />
      <section style={{ padding: '0 3rem' }}>
        <button
          type='button'
          className={classes.submit}
          onClick={redirectHandler}
        >
          LOG IN
        </button>
      </section>
      <p className={classes.tagline}>Don't have an account yet? </p>
      <form className={classes.form} onSubmit={submitHandler}>
        <h2>Recruiter Sign Up</h2>
        <input
          className={classes.input}
          type='text'
          placeholder='Recruiter Email'
          ref={email}
          required
        />
        <input
          className={classes.input}
          type='password'
          placeholder='Recruiter Password'
          ref={password}
          required
        />
        <button type='submit' className={classes.submit}>
          SIGN UP
        </button>
        <p className={classes.clearfix}>
          Contact{' '}
          <a href='mailto:tech@dailyprincetonian.com'>
            tech@dailyprincetonian.com
          </a>{' '}
          for assistance.
        </p>
      </form>
      <section style={{ height: '200px' }}></section>
    </div>
  );
}

export default SignUp;
